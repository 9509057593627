<template>
  <div>
    <!-- Table Container Card -->
    <user-password-edit :user-info="userInfo" />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">Product Category</h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Find User"
            />
          </div>
          <b-button
            variant="primary"
            class="px-3 mobile-w100"
            :to="{ name: 'product-category-create' }"
          >
            <!-- v-if="ability.can('create', 'user')" -->
            <span class="text-nowrap"
              ><feather-icon size="14" icon="PlusIcon" /> Category</span
            >
          </b-button>
        </div>
      </div>

      <b-table
        ref="refProductCategoryListTable"
        class="position-relative"
        :items="fetchProductCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- <template #cell(name)="data">
          <div v-if="data.item.name != null">
            {{ data.item.name }}<br />
            <b-badge
              v-for="team in data.item.teams"
              v-bind:style="{
                'background-color': team.background_color,
                color: team.font_color,
              }"
              :key="`team_${team.id}`"
              >{{ team.en_name }}
            </b-badge>
          </div>
        </template> -->
        <template #cell(has_image)="data">
          <div v-if="data.item.has_image" size="18" class="mr-50 text-success">&#10003</div>
          <div v-else size="18" class="mr-50 text-danger">&#x2717</div>
        </template>
        
        <template #cell(image_path)="data">
          <el-image
          v-if="data.item.image_path"
                    style="width: 15%; aspect-ratio: 10 / 3; padding: 0"
                    fit="cover"
                    :src="`${uploadUrl}${data.item.image_path}`"
                    :preview-src-list="[`${uploadUrl}${data.item.image_path}`]"
                  >
                  </el-image>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            class="action-trigger-btn"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
              <!-- v-if="ability.can('update', 'user')" -->
            <b-dropdown-item
              :to="{ name: 'product-category-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProductCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BFormGroup,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useProductCategoriesList from "./useProductCategoriesList";
import productCategoryStoreModule from "../productCategoryStoreModule";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    addRemarksShow(user_id) {
      this.newRemark.user_id = user_id;
      this.$bvModal.show("add-remarks");
    },
    addRemarksHide() {
      this.$bvModal.hide("add-remarks");
    },
    deleteRemark(id) {
      this.show = true;
      store
        .dispatch("app-user/deleteRemark", { id: id })
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.editRemarkBoolean = false;
          this.$bvModal.hide("remarks-modal");
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    confirmEdit(remark) {
      this.show = true;
      this.editRemarkBoolean = false;
      store
        .dispatch("app-user/addNewRemark", remark)
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.editRemarkBoolean = false;
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    editRemark() {
      this.editRemarkBoolean = true;
    },
    addNewRemarks() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-user/addNewRemark", this.newRemark)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.addRemarksHide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    openModel(remark) {
      this.remarks = remark;
      this.$bvModal.show("remarks-modal");
    },
    hideModal() {
      console.log("remarks clicked");
      this.$bvModal.hide("remarks-modal");
    },
    onBtnExport() {
      console.log("user export");
      store
        .dispatch("app-user/fetchProductCategoriesExport")
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `Users Export List`);
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    triggerResetPasswordModal(item) {
      console.log("triggerResetPasswordModal", item);
      this.userInfo.id = item.id;
      this.userInfo.name = item.name;
      this.userInfo.password = "";
      this.userInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-user-password");
    },
    handleDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the user record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-user/deleteUser", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      userInfo: { id: 0, password: "", password_confirmation: "" },
      remarks: [],
      newRemark: {
        user_id: null,
        remarks: null,
      },
      editRemarkBoolean: false,
      show: false,
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-product-categories";

    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        productCategoryStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchProductCategories,
      tableColumns,
      perPage,
      currentPage,
      totalProductCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductCategoryListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
      is_active,
      ability,
    } = useProductCategoriesList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchProductCategories,
      tableColumns,
      perPage,
      currentPage,
      totalProductCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductCategoryListTable,
      refetchData,
      ability,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      statusFilter,
      is_active,

      uploadUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
