import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useProductCategoriesList() {
  // Use toast
  const toast = useToast();

  const refProductCategoryListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: "id", label: "Category ID", sortable: true },
    { key: "actions" },
    {
      key: "name",
      stickyColumn: true,
      label: "Category Name",
      sortable: true,
    },
    { key: "hierarchy_string", sortable: true },
    { key: "has_image", sortable: true },
    { key: "image_path", sortable: true, label: "Image" },
  ];
  const perPage = ref(10);
  const totalProductCategories = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);
  const is_active = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refProductCategoryListTable.value
      ? refProductCategoryListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProductCategories.value,
    };
  });

  const refetchData = () => {
    console.log("refProductCategoryListTable", refProductCategoryListTable);
    refProductCategoryListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter, is_active], () => {
    refetchData();
  });

  const fetchProductCategories = (ctx, callback) => {
    store
      .dispatch("app-product-categories/fetchProductCategories", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response);
        const { product_categories, total } = response.data;
        callback(product_categories);
        totalProductCategories.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching product categories list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchProductCategories,
    tableColumns,
    perPage,
    currentPage,
    totalProductCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductCategoryListTable,
    ability,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    statusFilter,
    is_active,
  };
}
